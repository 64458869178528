body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

input {
    padding: 10px;
    font-size: 1.2em;
}

button {
    border: none;
    padding: 10px;
    background: green;
    color: #fff;
    font-size: 1.2em;
    font-weight: bold;
}


.container {
    display: flex;
    justify-content: center;
}

.chat-box {
    box-shadow: 0 2.8px 2.2px rgba(255, 0, 0, 0.034), 0 6.7px 5.3px rgba(0, 0, 0, 0.048), 0 12.5px 10px rgba(0, 0, 0, 0.06), 0 22.3px 17.9px rgba(0, 0, 0, 0.072), 0 41.8px 33.4px rgba(0, 0, 0, 0.086), 0 100px 80px rgba(0, 0, 0, 0.12);
    margin: 40px 50px;
    padding: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
}


.chat-content {
    width: 80%;
}

.chat-result {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #000;
}

.chat-lastResults {
    display: flex;
    justify-content: center;
}

.send-message {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.send-message-buttons {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}

.input-message {
    width: 90%;
    border-radius: 50px;
}

ul {
    padding: 0;
    list-style-type: none;
}

.send-button {
    border-radius: 50px;
    cursor: pointer;
}

.red {
    background: rgb(255, 0, 0);
}

.black {
    background: rgb(0, 0, 0);
}

.message {
    padding: 5px;
    width: auto;
    display: flex;
    flex-direction: row;
    box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
    margin: 5px 10px;
}

.message-data {
    padding: 5px;
}

.avatar {
    background-color: cornflowerblue;
    padding: 3px 5px;
    border-radius: 5px;
    color: #fff;
}

.bets {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.chat-bets {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.board {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 22rem;
    flex-wrap: wrap;
    gap: 1rem;
    justify-content: center;
    align-content: center;
}

.board-slotmachine {
    display: flex;
    flex-direction: row;
    align-items: center;
    min-width: 20rem;
    flex-wrap: wrap;
    gap: 1rem;
    justify-content: center;
    border-style: double;
    padding: 1rem;
}

.content-slotmachine {
    display: flex;
    flex-direction: column;
    align-items: center;
    align-content: center;
}

.send-bet-slotmachine {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 1rem;
}


.column-slotmachine {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    border: 1px solid;
}

.slotmachine-cell-valid {
    min-width: 3rem;
    background-color: rgb(134, 255, 160);
    height: 3rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.slotmachine-cell {
    min-width: 3rem;
    background-color: gray;
    height: 3rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.cell {
    width: 3rem;
    background-color: gray;
    height: 3rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.blackjack-result {
    display: flex;
    flex-direction: column;
    padding: 0.5rem;
    align-items: center;
    width: 22rem;
    flex-wrap: wrap;
    gap: 1rem;
    justify-content: center;
    align-content: center;
    border: 1px solid #000;
}

.blackjack-cell {
    min-width: 3rem;
    background-color: rgb(7, 189, 77);
    height: 3rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.blackjack-board {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-width: 22rem;
    padding: 0.5rem;
    gap: 1rem;
    justify-content: center;
    align-content: center;
    border: 1px solid #000;
}

.content {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    justify-content: center;
    align-content: center;
}

.blackjack-cards {
    display: flex;
    align-items: center;
    gap: 1rem;
    justify-content: center;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: center;
    border: 1px solid #000;
}

.blackjack-buttons {
    display: flex;
    align-items: center;
    gap: 1rem;
    justify-content: center;
    align-content: center;
}

.blackjack-send-button {
    border-radius: 50px;
    cursor: pointer;
    height: 2rem;
    width: 6rem;
    font-size: 0.7rem;
    display: flex;
    justify-content: center;
    align-items: center;
}